import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {ReservationsListingState} from '../reducers/reservationListingSlice';

export const selectReservationsListingSlice = (state: RootState) => {
    return state.reservations;
};

export const reservationsListingSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.reservations
);
export const isReservationsListingLoadingSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.isLoading
);
export const reservationsListingPaginationSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.pagination
);

export const reservationsListingMetadataSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.metadata
);

export const reservationsListingFiltersSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.filters
);

export const reservationsListingInitializedSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.isInitialized
);
export const reservationsListingErrorSelector = createSelector(
    [selectReservationsListingSlice],
    (state: ReservationsListingState) => state.error
);
