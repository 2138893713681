import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Translation, Pagination, Loader, LoaderType} from 'reservation-common-web';
import {changePagination, getReservationsList, IPaginationParams} from '../../store/reducers/reservationListingSlice';
import {
    isReservationsListingLoadingSelector,
    reservationsListingFiltersSelector,
    reservationsListingMetadataSelector,
    reservationsListingPaginationSelector,
    reservationsListingSelector,
} from '../../store/selectors/reservationListingSelector';
import Table from '../Table';
import SearchPanel from '../SearchPanel';

const Reservations: React.FC = () => {
    const reservations = useSelector(reservationsListingSelector);
    const listMetadata: {[key: string]: any} | null = useSelector(reservationsListingMetadataSelector);
    const isLoading = useSelector(isReservationsListingLoadingSelector);
    const paginationData: IPaginationParams = useSelector(reservationsListingPaginationSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReservationsList());
    }, []);

    const changePage = (params: IPaginationParams) => {
        dispatch(changePagination(params));
    };

    const downloadReport = () => {
        console.log('Download report');
    };

    return (
        <>
            <div className="header">
                <h2 className="title">
                    <Translation text="reservations_page.title" />
                </h2>
                <div className="actions-container">
                    <button onClick={downloadReport} className="btn-back">
                        <span className="btn-icon icon-print" />
                        <Translation text={'reservations_page.print'} />
                    </button>
                    <SearchPanel />
                </div>
            </div>
            <div className="content">
                {reservations?.length === 0 || reservations === null ? (
                    <p className="no-data-info">
                        <Translation text="reservations_page.no_data" />
                    </p>
                ) : (
                    <>
                        <Table data={reservations} />
                        <Pagination
                            listMetadata={listMetadata}
                            changePage={changePage}
                            itemsPerPage={paginationData.itemsPerPage}
                            currentPage={paginationData.page}
                        />
                    </>
                )}
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </div>
        </>
    );
};

export default Reservations;
