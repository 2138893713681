import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, ImageType} from 'reservation-common-web';

const formPresentationFormConfig = (t: any): FormikFieldConfig[] => {
    return [
        {
            name: 'color',
            label: t('form_page.form_presentation.form.labels.color'),
            type: FormikFieldTypes.COLORPICKER,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'background',
            label: t('form_page.form_presentation.form.labels.background_image'),
            isRequired: true,
            type: FormikFieldTypes.FILE,
            validation: Yup.string().required(t('validation.required')),
            className: 'avatar-upload',
            fileUploadRequirements: {
                extensions: [ImageType.JPG, ImageType.JPEG, ImageType.PNG],
                size: 4,
                height: 1080,
                width: 1920,
            },
        },
    ];
};

export default formPresentationFormConfig;
