import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, FormikRegexSettings} from 'reservation-common-web';

const reservationsSearchFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'search',
            label: t('reservations_page.search'),
            isRequired: false,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string(),
            className: '',
        },
        {
            name: 'roomId',
            label: t('reservations_page.room'),
            isRequired: false,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string(),
            className: '',
        },
        {
            name: 'dateRange',
            label: t('reservations_page.date'),
            isRequired: false,
            type: FormikFieldTypes.DATERANGE,
            validation: Yup.string(),
            className: '',
        },
    ];
};

export default reservationsSearchFormConfig;
