import {PayloadAction} from '@reduxjs/toolkit';
import {
    createBaseReducerSlice,
    IBaseReducerState,
    IOpenHoursOutput,
    IPhoneInput,
    IRestaurantConfigurationInput,
    IRestaurantConfigurationOutput,
    IRestaurantInput,
    IRestaurantOutput,
    IRoomInput,
    IRoomOutput,
} from 'reservation-common-web';

export interface IRestaurantState extends IBaseReducerState {
    configuration: IRestaurantConfigurationInput | undefined;
    isLoading: boolean;
    isInitialized: boolean;
    name: string;
    address: string;
    email: string;
    phone: string | undefined;
    slug: string;
    error: string | null;
    rooms: IRoomInput[];
    createRoomModalOpen: boolean;
    isRestaurantDetailsValid: boolean;
    isRestaurantRoomsValid: boolean;
    isRestaurantHeadingValid: boolean;
    isOpeningHoursValid: boolean;
    isFormAppearanceValid: boolean;
}

export interface ISetRestaurantFromOutput {
    isInitialized: boolean;
    name: string;
    address: string;
    email: string;
    phone: string | undefined;
    slug: string;
    rooms: IRoomOutput[];
    configuration: IRestaurantConfigurationOutput | undefined;
}

export interface ISetRestaurant {
    restaurant: IRestaurantInput | null;
}

export interface ISetRestaurantDetails {
    name: string;
    address: string;
    email: string;
    phone: string | undefined;
    slug: string;
    privacyPolicy: string;
}

export interface ISetFormAppearance {
    color: string;
    background: string;
}

export interface ISetRestaurantHeading {
    avatar: string;
    name: string;
    shortDescription: string;
    initialDescription: string;
}

export interface ISetOpeningHours {
    openHours: IOpenHoursOutput;
}
export interface ISetRedirectToRestaurantList {
    redirectToRestaurantList: boolean;
}

export interface IGetRestaurant {
    restaurantId: string;
}

const initialState: IRestaurantState = {
    configuration: undefined,
    isLoading: false,
    isInitialized: false,
    name: '',
    address: '',
    email: '',
    phone: '',
    slug: '',
    error: null,
    rooms: [],
    createRoomModalOpen: false,
    isRestaurantDetailsValid: false,
    isRestaurantHeadingValid: false,
    isRestaurantRoomsValid: true,
    // change validitity of rooms when isPremium is implemented - then you will have possibility to edit that
    isOpeningHoursValid: false,
    isFormAppearanceValid: false,
};

const restaurantSlice = createBaseReducerSlice({
    name: 'restaurant',
    initialState: initialState,
    reducers: {
        setRestaurant: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurant>) => {
                return {
                    ...state,
                    configuration: action.payload.restaurant?.configuration,
                    name: action.payload.restaurant?.name ? action.payload.restaurant?.name : '',
                    address: action.payload.restaurant?.address ? action.payload.restaurant?.address : '',
                    email: action.payload.restaurant?.email ? action.payload.restaurant?.email : '',
                    phone: action.payload.restaurant?.phone ? action.payload.restaurant?.phone : undefined,
                    rooms: action.payload.restaurant?.rooms ? action.payload.restaurant?.rooms : [],
                };
            },
            prepare(restaurant: IRestaurantInput) {
                return {
                    payload: {restaurant: restaurant},
                };
            },
        },
        setRestaurantFromOutput: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurantFromOutput>) => {
                console.log(action, 'action', action.payload, 'action.payload.restaurant');
                const configurationAsInput: IRestaurantConfigurationInput = {
                    name: action.payload?.name,
                    privacyPolicy: action.payload.configuration?.privacyPolicy || '',
                    openHours: action.payload.configuration?.openHours || {},
                    color: action.payload.configuration?.color || '',
                    background: action.payload.configuration?.background?.fileUrls?.original || '',
                    avatar: action.payload.configuration?.avatar?.fileUrls?.original || '',
                    shortDescription: action.payload.configuration?.shortDescription || '',
                    initialDescription: action.payload.configuration?.initialDescription || '',
                    floorPlan: '',
                };

                return {
                    ...state,
                    configuration: configurationAsInput,
                    name: action.payload.name,
                    address: action.payload.address,
                    email: action.payload.email,
                    phone: action.payload.phone,
                    rooms: action.payload.rooms,
                    isInitialized: action.payload.isInitialized,
                    slug: action.payload.slug,
                };
            },
            prepare(restaurant: ISetRestaurantFromOutput) {
                return {
                    payload: restaurant,
                };
            },
        },
        setRestaurantDetails: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurantDetails>) => {
                return {
                    ...state,
                    name: action.payload.name,
                    address: action.payload.address,
                    email: action.payload.email,
                    phone: action.payload.phone,
                    slug: action.payload.slug,
                    configuration: {
                        privacyPolicy: action.payload.privacyPolicy,
                    },
                };
            },
            prepare(restaurantDetails: ISetRestaurantDetails) {
                return {
                    payload: restaurantDetails,
                };
            },
        },
        setRestaurantDetailsValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isRestaurantDetailsValid: action.payload,
                };
            },
            prepare(isRestaurantDetailsValid: boolean) {
                return {
                    payload: isRestaurantDetailsValid,
                };
            },
        },
        setOpeningHours: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetOpeningHours>) => {
                return {
                    ...state,
                    configuration: {
                        ...state.configuration,
                        openHours: action.payload.openHours,
                    },
                };
            },
            prepare(openingHours: ISetOpeningHours) {
                return {
                    payload: openingHours,
                };
            },
        },
        setOpeningHoursValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isOpeningHoursValid: action.payload,
                };
            },
            prepare(isOpeningHoursValid: boolean) {
                return {
                    payload: isOpeningHoursValid,
                };
            },
        },
        setRooms: {
            reducer: (state: IRestaurantState, action: PayloadAction<IRoomInput[]>) => {
                return {
                    ...state,
                    rooms: action.payload,
                };
            },
            prepare(rooms: IRoomInput[]) {
                return {
                    payload: rooms,
                };
            },
        },
        setFormAppearance: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetFormAppearance>) => {
                return {
                    ...state,
                    configuration: {
                        ...state.configuration,
                        color: action.payload.color,
                        background: action.payload.background,
                    },
                };
            },
            prepare(formAppearance: ISetFormAppearance) {
                return {
                    payload: {color: formAppearance.color, background: formAppearance.background},
                };
            },
        },
        setFormAppearanceValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isFormAppearanceValid: action.payload,
                };
            },
            prepare(isFormAppearanceValid: boolean) {
                return {
                    payload: isFormAppearanceValid,
                };
            },
        },
        setRestaurantHeading: {
            reducer: (state: IRestaurantState, action: PayloadAction<IRestaurantConfigurationInput>) => {
                return {
                    ...state,
                    configuration: {
                        ...state.configuration,
                        avatar: action.payload.avatar,
                        name: action.payload.name,
                        shortDescription: action.payload.shortDescription,
                        initialDescription: action.payload.initialDescription,
                    },
                };
            },
            prepare(restaurantHeading: ISetRestaurantHeading) {
                return {
                    payload: {
                        avatar: restaurantHeading.avatar,
                        name: restaurantHeading.name,
                        shortDescription: restaurantHeading.shortDescription,
                        initialDescription: restaurantHeading.initialDescription,
                    },
                };
            },
        },
        setRestaurantHeadingValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isRestaurantHeadingValid: action.payload,
                };
            },
            prepare(isRestaurantHeadingValid: boolean) {
                return {
                    payload: isRestaurantHeadingValid,
                };
            },
        },
        getRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(restaurantId: string) {
                return {
                    payload: {restaurantId: restaurantId},
                };
            },
        },
        createRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(restaurant: IRestaurantInput) {
                return {
                    payload: restaurant,
                };
            },
        },
        createTeamRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(restaurant: IRestaurantInput) {
                return {
                    payload: {restaurant: restaurant},
                };
            },
        },

        updateRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(id: string, restaurant: IRestaurantInput) {
                return {
                    payload: {id: id, restaurant: restaurant},
                };
            },
        },
        deleteRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(restaurantId: string) {
                return {
                    payload: {restaurantId},
                };
            },
        },
        setLoading: (state: IRestaurantState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: IRestaurantState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    setRestaurant,
    setLoading,
    setError,
    setInitialized,
    getRestaurant,
    updateRestaurant,
    createRestaurant,
    deleteRestaurant,
    setFormAppearance,
    setRooms,
    setRestaurantDetails,
    setRestaurantHeading,
    setOpeningHours,
    setRestaurantDetailsValid,
    setFormAppearanceValid,
    setRestaurantHeadingValid,
    setOpeningHoursValid,
    setRestaurantFromOutput,
} = restaurantSlice.actions;
export default restaurantSlice.reducer;
