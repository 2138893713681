import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {FormGenerator} from 'reservation-common-web';
import {changeFilters, IReservationFilters} from '../../store/reducers/reservationListingSlice';
import reservationsSearchFormConfig from './reservationsSearchFormConfig';

const SearchPanel: React.FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const initialValues = {
        search: '',
        roomId: '',
        dateRange: '',
    };

    const submitSearch = (values: any) => {
        const dates = values.dateRange.split(' - ');
        const filtersPayload: IReservationFilters = {
            search: values.search,
            'date[before]': dates ? new Date(dates[1]).toDateString() : null,
            'date[after]': dates ? new Date(dates[0]).toDateString() : null,
            'room.id[]': values.roomId ? [values.roomId] : null,
        };
        dispatch(changeFilters(filtersPayload));
    };

    return (
        <div className="search-panel">
            <div className="reservations-search-form-container">
                <FormGenerator
                    config={{
                        fields: reservationsSearchFormConfig(t),
                        initialValues,
                        onSubmit: submitSearch,
                        debouncedChangeTimeout: 1000,
                        formId: 'reservation-search-form',
                        formClassName: 'reservation-search-form',
                    }}
                />
            </div>
        </div>
    );
};

export default SearchPanel;
