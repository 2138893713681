import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IRestaurantState} from '../reducers/restaurantSlice';
import {IRestaurantInput} from 'reservation-common-web';

export const selectRestaurantSlice = (state: RootState) => {
    return state.restaurant;
};

export const restaurantPayloadSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    const defaultRoom = {
        id: null,
        name: null,
        default: true,
        maxCount: 0,
        maxCountPerReservation: 0,
        hourStart: '00:00',
        hourEnd: '23:59',
        step: 15,
        prepayRequired: false,
        prepayPerPerson: {
            amount: '0',
            currency: 'PLN',
        },
    };

    const restaurantPayload: IRestaurantInput = {
        name: state.name,
        address: state.address,
        email: state.email,
        phone: state.phone || '',
        configuration: {
            name: state.configuration?.name || '',
            floorPlan: null,
            avatar: state.configuration?.avatar || '',
            shortDescription: state.configuration?.shortDescription || '',
            initialDescription: state.configuration?.initialDescription || '',
            color: state.configuration?.color ? state.configuration?.color?.substring(1) : '',
            background: state.configuration?.background || '',
            privacyPolicy: state.configuration?.privacyPolicy || '',
            openHours: state.configuration?.openHours || {},
        },
        rooms: state.rooms.length ? state.rooms : [defaultRoom],
    };
    return restaurantPayload;
});

export const restaurantDetailsSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    return {
        name: state.name,
        address: state.address,
        email: state.email,
        phone: state.phone,
        slug: state.slug,
        privacyPolicy: state.configuration?.privacyPolicy,
    };
});

export const openingHoursSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.configuration?.openHours);

export const restaurantRoomsSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.rooms);
export const restaurantRoomDivisionSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => '' // TODO PT: zglosic brak roomLayout na serwerze
);
export const restaurantFormAppearanceSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    return {
        color: state.configuration?.color,
        background: state.configuration?.background,
    };
});

export const restaurantHeadingSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => {
    return {
        avatar: state.configuration?.avatar,
        name: state.configuration?.name,
        shortDescription: state.configuration?.shortDescription,
        initialDescription: state.configuration?.initialDescription,
    };
});

export const isOpeningHoursValidSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isOpeningHoursValid);

export const isRestaurantDetailsValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantDetailsValid
);
export const isRestaurantHeadingValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantHeadingValid
);
export const isRestaurantRoomsValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantRoomsValid
);
export const isRestaurantAppearanceValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isFormAppearanceValid
);
export const isRestaurantFormValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) =>
        state.isRestaurantDetailsValid && state.isRestaurantHeadingValid && state.isRestaurantRoomsValid && state.isFormAppearanceValid
);

export const isRestaurantLoadingSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isLoading);

export const restaurantListingInitializedSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isInitialized
);
export const restaurantListingErrorSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.error);
