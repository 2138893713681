import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator} from 'reservation-common-web';
import CustomCard from '../../CustomCard';
import roomDetailsFormConfig from './roomDetailsFormConfig';
import {isRestaurantDetailsValidSelector, restaurantDetailsSelector} from '../../../store/selectors/restaurantSelector';
import {ISetRestaurantDetails, setRestaurantDetails, setRestaurantDetailsValid} from '../../../store/reducers/restaurantSlice';

const RoomDetailsForm: React.FC = () => {
    const restaurantRoomDetails = useSelector(restaurantDetailsSelector);
    const restaurantRoomDetailsValid = useSelector(isRestaurantDetailsValidSelector);
    const dispatch = useDispatch();
    const initialValues = {
        name: restaurantRoomDetails.name || '',
        address: restaurantRoomDetails.address || '',
        email: restaurantRoomDetails.email || '',
        phone: restaurantRoomDetails.phone || '',
        privacy_policy: restaurantRoomDetails.privacyPolicy || '',
    };
    const {t} = useTranslation();
    const toggleValidity = (valid: boolean) => (valid !== restaurantRoomDetailsValid ? dispatch(setRestaurantDetailsValid(valid)) : null);
    const saveRoomDetails = (values: any) => {
        const restaurantRoomDetailsPayload: ISetRestaurantDetails = {
            name: values.name,
            address: values.address,
            email: values.email,
            phone: values.phone ? values.phone.toString() : '',
            slug: restaurantRoomDetails.slug ? restaurantRoomDetails.slug : '',
            privacyPolicy: values.privacy_policy,
        };
        if (restaurantRoomDetails === restaurantRoomDetailsPayload) {
            return;
        }
        dispatch(setRestaurantDetails(restaurantRoomDetailsPayload));
    };

    return (
        <CustomCard
            title="form_page.room_details.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: roomDetailsFormConfig(t),
                            initialValues,
                            onSubmit: saveRoomDetails,
                            debouncedChangeTimeout: 1000,
                            formId: 'change-room-details-form',
                            formClassName: '',
                        }}
                        isFormValid={toggleValidity}
                    />
                </div>
            }
        />
    );
};

export default RoomDetailsForm;
