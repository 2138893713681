import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, IRoomInput, formatTimeToHHmm} from 'reservation-common-web';
import CustomCard from '../../CustomCard';
import reservationDetailsFormConfig from './reservationDetailsFormConfig';
import {restaurantRoomsSelector} from '../../../store/selectors/restaurantSelector';
import {setRestaurantDetailsValid, setRooms} from '../../../store/reducers/restaurantSlice';

const ReservationDetailsForm: React.FC = () => {
    const dispatch = useDispatch();
    const saveReservationDetails = (values: any) => {
        const hourStartFormatted = values.min_reservation_hour ? formatTimeToHHmm(values.min_reservation_hour) : '';
        const hourEndFormatted = values.max_reservation_hour ? formatTimeToHHmm(values.max_reservation_hour) : '';
        const reservationDetailsPayload: IRoomInput = {
            id: null,
            name: 'default room',
            default: true,
            maxCount: values.max_people_in_single_reservation,
            maxCountPerReservation: values.max_people_in_reservation,
            hourStart: hourStartFormatted,
            hourEnd: hourEndFormatted,
            step: values.reservation_interval,
            prepayRequired: false,
            prepayPerPerson: {
                amount: '1000',
                currency: 'PLN',
            },
        };
        dispatch(setRooms([reservationDetailsPayload]));
    };

    const initialRoomValue = useSelector(restaurantRoomsSelector);
    console.log('initialRoomValue', initialRoomValue);
    const initialValues = {
        max_people_in_reservation: initialRoomValue[0]?.maxCount || '',
        max_people_in_single_reservation: initialRoomValue[0]?.maxCountPerReservation || '',
        min_reservation_hour: initialRoomValue[0]?.hourStart ? new Date(`1970-01-01T${initialRoomValue[0].hourStart}:00`) : '',
        max_reservation_hour: initialRoomValue[0]?.hourEnd ? new Date(`1970-01-01T${initialRoomValue[0].hourEnd}:00`) : '',
        reservation_interval: initialRoomValue[0]?.step || '',
    };
    const {t} = useTranslation();

    return (
        <CustomCard
            title="form_page.reservations.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: reservationDetailsFormConfig(t),
                            initialValues,
                            onSubmit: saveReservationDetails,
                            debouncedChangeTimeout: 1000,
                            formId: 'change-reservation-details-form',
                            formClassName: '',
                        }}
                        isFormValid={(valid: boolean) => dispatch(setRestaurantDetailsValid(valid))}
                    />
                </div>
            }
        />
    );
};

export default ReservationDetailsForm;
