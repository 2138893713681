import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';

const reservationDetailsFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'max_people_in_reservation',
            label: t('form_page.reservations.form.labels.max_people_in_reservation'),
            type: FormikFieldTypes.NUMBER,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'max_people_in_single_reservation',
            label: t('form_page.reservations.form.labels.max_people_in_single_reservation'),
            type: FormikFieldTypes.NUMBER,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'min_reservation_hour',
            label: t('form_page.reservations.form.labels.min_reservation_hour'),
            type: FormikFieldTypes.TIME,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'max_reservation_hour',
            label: t('form_page.reservations.form.labels.max_reservation_hour'),
            type: FormikFieldTypes.TIME,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'reservation_interval',
            label: t('form_page.reservations.form.labels.reservation_interval'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            options: [
                {label: t('15 min'), value: 15},
                {label: t('30 min'), value: 30},
                {label: t('60 min'), value: 60},
            ],
            className: '',
        },
    ];
};

export default reservationDetailsFormConfig;
