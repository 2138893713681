import React from 'react';
import {Translation, IRestaurantInput, IRestaurantConfigurationInput, IRoomInput} from 'reservation-common-web';
import RoomDetailsForm from './RoomDetailsForm';
import OpeningHoursForm from './OpeningHoursForm';
import ReservationDetailsForm from './ReservationDetailsForm';
import RoomDivisionForm from './RoomDivision';
import FormPresentationForm from './FormPresentation';
import FormTitleForm from './FormTitle';
import {useDispatch, useSelector} from 'react-redux';
import {createRestaurant} from '../../store/reducers/restaurantSlice';
import {isRestaurantFormValidSelector, restaurantPayloadSelector} from '../../store/selectors/restaurantSelector';

interface IReservationFormProps {}

const ReservationForm: React.FC<IReservationFormProps> = ({}) => {
    const isLoading = false; // ToDo add isLoading from corresponding slice
    const dispatch = useDispatch();
    const restaurantPayload = useSelector(restaurantPayloadSelector);

    const createRestaurantAction = () => {
        console.log('restaurantPayload', restaurantPayload);
        dispatch(createRestaurant(restaurantPayload));
    };
    const isFormValid = useSelector(isRestaurantFormValidSelector);
    const isPremium = false; // ToDo add isPremium from corresponding slice
    return (
        <div>
            <div className="header">
                <h2 className="title">
                    <Translation text="form_page.form" />
                </h2>
            </div>
            <div className="content">
                <RoomDetailsForm />
                <OpeningHoursForm />
                {isPremium ? <RoomDivisionForm /> : <ReservationDetailsForm />}
                <FormPresentationForm />
                <FormTitleForm />
                <button className="btn btn-primary" type="submit" onClick={createRestaurantAction} disabled={!isFormValid}>
                    <Translation text="form_page.save" />
                </button>
            </div>
        </div>
    );
};

export default ReservationForm;
