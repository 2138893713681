import {
    AlertType,
    DetailsSuccessActionsFunction,
    IRestaurantOutput,
    addAlert,
    closeModal,
    createRestaurantAPI,
    createOperationEpic,
    deleteRestaurantAPI,
    handleApiError,
    updateRestaurantAPI,
    authTokenSelector,
    IRestaurantInput,
    ISetAccountState,
    setAccountState,
} from 'reservation-common-web';
import {push} from 'react-router-redux';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {
    createRestaurant,
    deleteRestaurant,
    ISetRestaurantFromOutput,
    setError,
    setLoading,
    setRestaurant,
    setRestaurantFromOutput,
    updateRestaurant,
} from '../reducers/restaurantSlice';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {PayloadAction} from '@reduxjs/toolkit';
import {of, EMPTY} from 'rxjs';

const translateRestaurantPayloadToInput = (restaurant: IRestaurantOutput | undefined): ISetRestaurantFromOutput | null => {
    if (!restaurant) {
        return null;
    }

    const restaurantStateObject: ISetRestaurantFromOutput = {
        name: restaurant.name,
        address: restaurant.address,
        email: restaurant.email,
        phone: restaurant.phone,
        isInitialized: true,
        slug: restaurant.slug,

        configuration: {
            id: restaurant.configuration.id,
            privacyPolicy: restaurant.configuration.privacyPolicy,
            openHours: restaurant.configuration.openHours,
            color: restaurant.configuration.color ? `#${restaurant.configuration.color}` : '',
            background: restaurant.configuration.background,
            avatar: restaurant.configuration.avatar,
            title: restaurant.configuration.title,
            shortDescription: restaurant.configuration.shortDescription,
            initialDescription: restaurant.configuration.initialDescription,
        },
        rooms: restaurant.rooms,
    };
    return restaurantStateObject;
};

const createRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (res: IRestaurantOutput) => {
    console.log('createRestaurantSuccessActions', res);

    return [
        addAlert({message: 'restaurants.restaurantOperation.restaurantCreated'}),
        setLoading(false),
        push('/panel'),
        setRestaurantFromOutput(res),
    ];
};

const updateRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (restaurant: IRestaurantOutput) => {
    return [addAlert({message: 'restaurants.restaurantOperation.restaurantUpdated'}), setLoading(false), setRestaurant(restaurant)];
};

const deleteRestaurantSuccessActions: DetailsSuccessActionsFunction<null> = () => {
    return [addAlert({message: 'restaurants.restaurantOperation.restaurantDeleted'}), setLoading(false), closeModal(), push('/panel/')];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const createRestaurantEpic = createOperationEpic<IRestaurantOutput>(
    createRestaurantAPI,
    createRestaurantSuccessActions,
    errorActions,
    createRestaurant().type
);

const updateRestaurantEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(updateRestaurant().type),
        switchMap((action: PayloadAction<{id: string; restaurant: IRestaurantInput}>) => {
            const authToken = authTokenSelector(state$.value);
            return updateRestaurantAPI(authToken, {id: action.payload.id, restaurant: action.payload.restaurant}).pipe(
                mergeMap((res: IRestaurantOutput) => of(...updateRestaurantSuccessActions(res))),
                catchError((error) => {
                    return errorActions(error);
                })
            );
        })
    );
const getRestaurantFromAccount: Epic = (action$, state$) =>
    action$.pipe(
        ofType(setAccountState().type),
        switchMap((action: PayloadAction<ISetAccountState>) => {
            const restaurantStateObject = translateRestaurantPayloadToInput(action.payload.account.restaurant);
            console.log('restaurantFromAccount', restaurantStateObject, action.payload, 'action.payload');
            return restaurantStateObject ? of(setRestaurantFromOutput(restaurantStateObject)) : EMPTY;
        })
    );
const deleteRestaurantEpic = createOperationEpic<null>(
    deleteRestaurantAPI,
    deleteRestaurantSuccessActions,
    errorActions,
    deleteRestaurant().type
);

const restaurantEpic = combineEpics(
    createRestaurantEpic,
    createRestaurantEpic,
    getRestaurantFromAccount,
    updateRestaurantEpic,
    deleteRestaurantEpic
);

export default restaurantEpic;
