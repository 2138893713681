import React from 'react';

export const DefaultAvatar = () => (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="64" height="64" rx="32" fill="#D2D6DB"/>
    <path d="M43 36V37.4C43 39.3602 43 40.3403 42.6185 41.089C42.283 41.7475 41.7475 42.283 41.089 42.6185C40.3403 43 39.3602 43 37.4 43H27.6C25.6398 43 24.6597 43 23.911 42.6185C23.2525 42.283 22.717 41.7475 22.3815 41.089C22 40.3403 22 39.3602 22 37.4V36M38.3333 27.8333L32.5 22M32.5 22L26.6667 27.8333M32.5 22V36" stroke="#6C737F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default DefaultAvatar;
