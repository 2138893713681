import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator} from 'reservation-common-web';
import CustomCard from '../../CustomCard';
import formTitleFormConfig from './formTitleFormConfig';
import {restaurantHeadingSelector} from '../../../store/selectors/restaurantSelector';
import {ISetRestaurantHeading, setRestaurantHeading, setRestaurantHeadingValid} from '../../../store/reducers/restaurantSlice';

const FormTitleForm: React.FC = () => {
    const formHeading = useSelector(restaurantHeadingSelector);
    const dispatch = useDispatch();
    const saveFormTitle = (values: any) => {
        console.log(values);
        const titlePayload: ISetRestaurantHeading = {
            avatar: values.avatar,
            name: values.name,
            shortDescription: values.description,
            initialDescription: values.initial_information,
        };
        dispatch(setRestaurantHeading(titlePayload));
    };
    const initialValues = {
        avatar: formHeading.avatar || '',
        name: formHeading.name || '',
        description: formHeading.shortDescription || '',
        initial_information: formHeading.initialDescription || '',
    };
    const {t} = useTranslation();

    return (
        <CustomCard
            title="form_page.form_title.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: formTitleFormConfig(t),
                            initialValues,
                            onSubmit: saveFormTitle,
                            formId: 'change-form-title-form',
                            formClassName: '',
                            debouncedChangeTimeout: 1000,
                        }}
                        isFormValid={(valid: boolean) => dispatch(setRestaurantHeadingValid(valid))}
                    />
                </div>
            }
        />
    );
};

export default FormTitleForm;
