import React from 'react';
import { Field } from 'formik';

const FormikFieldRefWrapper = React.forwardRef<HTMLInputElement, any>(({ name, ...props }, ref) => (
    <Field name={name}>
        {({ field }: { field: any }) => (
            <input
                {...field}
                {...props}
                ref={ref} 
            />
        )}
    </Field>
));

export default FormikFieldRefWrapper;
