import {
    AlertType,
    IModelApiResponseViewObject,
    IReservationOutput,
    ListSuccessActionsFunction,
    addAlert,
    createFetchListEpic,
    flattenObj,
    getReservationListAPI,
    handleApiError,
    IRawRestQueryParams,
} from 'reservation-common-web';
import {combineEpics} from 'redux-observable';
import {
    setError,
    setLoading,
    getReservationsList,
    setReservationsList,
    setMetadata,
    changePagination,
    changeFilters,
} from '../reducers/reservationListingSlice';
import {RootState} from '../reducers';
import {reservationsListingFiltersSelector, reservationsListingPaginationSelector} from '../selectors/reservationListingSelector';

const reservationsListingSuccessActions: ListSuccessActionsFunction<IReservationOutput> = (
    reservations: IReservationOutput[],
    metadata: IModelApiResponseViewObject | null
) => {
    return [setReservationsList(reservations), setMetadata({metadata: metadata}), setLoading(false)];
};

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const getReservationListParameters = (state: RootState): IRawRestQueryParams | [] => {
    const queryParameters = reservationsListingFiltersSelector(state),
        pagination = reservationsListingPaginationSelector(state);
    const params = {
        ...pagination,
        ...queryParameters,
    };
    if (params) {
        const parametersFlattened = flattenObj(params);
        return parametersFlattened;
    }
    return [];
};

const getReservationListEpic = createFetchListEpic<IReservationOutput>(
    getReservationListAPI,
    reservationsListingSuccessActions,
    errorActions,
    getReservationsList().type,
    (state: RootState) => getReservationListParameters(state)
);

const changeReservationsListPaginationEpic = createFetchListEpic<IReservationOutput>(
    getReservationListAPI,
    reservationsListingSuccessActions,
    errorActions,
    changePagination().type,
    (state: RootState) => getReservationListParameters(state)
);

const changeReservationsListFiltersEpic = createFetchListEpic<IReservationOutput>(
    getReservationListAPI,
    reservationsListingSuccessActions,
    errorActions,
    changeFilters().type,
    (state: RootState) => getReservationListParameters(state)
);
const reservationListingEpic = combineEpics(
    getReservationListEpic,
    changeReservationsListPaginationEpic,
    changeReservationsListFiltersEpic
);

export default reservationListingEpic;
